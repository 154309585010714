<template>
  <v-container fluid>
    <CustomersAssetsSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Gestione Assets"
      :headers="headers"
      :actions="actions"
      module="customersAssets"
      :exportable="canUser('customers.assets', 'download')"
    >
      <template v-slot:item.customers_user="{ item }">
        <span v-if="item.customers_user">
          {{ item.customers_user.last_name }}
          {{ item.customers_user.first_name }}
        </span>
        <em v-else>Non specificato</em>
      </template>
      <template v-slot:item.serial_code="{ item }">
        {{ item.serial_code | placeholder('-') }}
      </template>
      <template v-slot:item.assigned_at="{ item }">
        {{ item.assigned_at | momentOr('DD/MM/YYYY', '-') }}
      </template>
      <template v-slot:item.contract_start_at="{ item }">
        {{ item.contract_start_at | momentOr('DD/MM/YYYY', '-') }}
      </template>
      <template v-slot:item.contract_end_at="{ item }">
        {{ item.contract_end_at | momentOr('DD/MM/YYYY', '-') }}
      </template>
      <template v-slot:item.has_kasko="{ item }">
        {{ item.has_kasko ? 'SÌ' : 'NO' }}
      </template>
      <template v-slot:footer>
        <v-dialog
          v-model="dialog"
          max-width="75%"
          :fullscreen="$vuetify.breakpoint.mobile"
          :retain-focus="false"
          @click:outside="modalClose"
        >
          <v-card>
            <v-card-title>Asset Cliente</v-card-title>
            <v-card-text>
              <CustomerAssetsForm
                v-if="dialog"
                :edit="editMode"
                @submitted="onSubmitted"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import CustomersAssetsSearch from '@components/customer_assets/CustomersAssetsSearch.vue'
import BaseTable from '@components/structure/base-table.vue'
import { mapActions, mapMutations } from 'vuex'
import { authComputed } from '@state/helpers.js'
import CustomerAssetsForm from '@components/customer_assets/CustomerAssetsForm.vue'

export default {
  name: 'AssetsSearch',
  components: { CustomersAssetsSearch, BaseTable, CustomerAssetsForm },
  page: {
    title: 'Ricerca',
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Sottoscrizione',
          value: 'plans_subscription.code',
        },
        {
          text: 'Utente Cliente',
          value: 'customers_user',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Brand',
          align: 'start',
          value: 'brand',
        },
        {
          text: 'Modello',
          value: 'model',
        },
        {
          text: 'Seriale',
          value: 'serial_code',
        },
        {
          text: 'IMEI/MSISDN',
          value: 'imei',
        },

        {
          text: 'Kasko',
          value: 'has_kasko',
        },
        {
          text: 'Data Assegnazione',
          value: 'assigned_at',
        },
        {
          text: 'Data Inserimento',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Mostra dettagli',
          icon: 'mdi-magnify',
          color: 'primary',
          to: (item) => ({ name: 'assets_detail', params: { id: item.id } }),
          onItemCondition: () => this.canUser('customers.assets', 'read'),
        },
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('customers.assets', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina asset',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('customers.assets', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    this.setScopes(['customer', 'product', 'customer_user', 'subscription'])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapActions('customersAssets', ['removeItem']),
    ...mapMutations('customersAssets', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      /* Allow for editing and creating new items (not currently used) */
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },

    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione dell'asset ?\nL'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      this.$refs.table.refresh()
    },
  },
}
</script>

<style scoped></style>
