var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CustomersAssetsSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"title":"Gestione Assets","headers":_vm.headers,"actions":_vm.actions,"module":"customersAssets","exportable":_vm.canUser('customers.assets', 'download')},scopedSlots:_vm._u([{key:"item.customers_user",fn:function(ref){
var item = ref.item;
return [(item.customers_user)?_c('span',[_vm._v(" "+_vm._s(item.customers_user.last_name)+" "+_vm._s(item.customers_user.first_name)+" ")]):_c('em',[_vm._v("Non specificato")])]}},{key:"item.serial_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.serial_code,'-'))+" ")]}},{key:"item.assigned_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.assigned_at,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.contract_start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.contract_start_at,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.contract_end_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.contract_end_at,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.has_kasko",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.has_kasko ? 'SÌ' : 'NO')+" ")]}},{key:"footer",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"75%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Asset Cliente")]),_c('v-card-text',[(_vm.dialog)?_c('CustomerAssetsForm',{attrs:{"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}}):_vm._e()],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }