<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormCustomers
        v-if="!currentCustomerId"
        v-model="customer_id"
        name="Cliente"
        hint="Ricerca per Cliente"
        clearable
        dense
      />
      <FormCustomersUsers
        v-model="customer_user_id"
        :customer_id="customer_id"
        label="Utente"
        :cols="2"
        :md="3"
        :lg="3"
        :xl="3"
        dense
        clearable
      />

      <FormItem
        v-model="serial_code"
        type="text"
        name="Seriale"
        hint="Ricerca per Seriale"
        :cols="2"
        dense
      />

      <FormItem
        v-model="imei"
        type="text"
        name="IMEI/MSISDN"
        hint="Ricerca per IMEI/MSISDN"
        :cols="2"
        dense
      />

      <FormProducts
        v-model="product_id"
        :cols="3"
        dense
        clearable
        prefill-on-load
      />

      <FormItem
        v-model="status"
        type="autocomplete"
        :values="statuses"
        name="Stato"
        multiple
        :cols="2"
        chips
        deletable-chips
        dense
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="has_kasko"
        name="Kasko"
        type="select"
        :values="[
          { value: 'true', text: 'SI' },
          { value: 'false', text: 'NO' },
        ]"
        hint="Ricerca per kasko"
        :cols="2"
        :md="2"
        :lg="2"
        :xl="2"
        dense
      />

      <FormItem
        v-model="is_spare"
        name="Scorta"
        type="select"
        :values="[
          { value: 'true', text: 'SI' },
          { value: 'false', text: 'NO' },
        ]"
        :cols="2"
        :md="2"
        :lg="2"
        :xl="2"
        dense
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="assigned_at"
        type="datepicker"
        name="Data Assegnazione"
        hint="Ricerca per data assegnazione"
        :cols="2"
        :md="3"
        :lg="2"
        :xl="2"
        range
        dense
      />

      <FormItem
        v-if="!currentCustomerId"
        v-model="activated_at"
        type="datepicker"
        name="Data Attivazione"
        hint="Ricerca per data attivazione"
        :cols="2"
        :md="3"
        :lg="2"
        :xl="2"
        range
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormProducts from '@components/forms/FormProducts.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'customersAssets/getFiltersFields',
  mutationType: 'customersAssets/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomersAssetsSearch',
  components: {
    FormProducts,
    FormCustomers,
    FormCustomersUsers,
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'brand',
      'model',
      'serial_code',
      'imei',
      'status',
      'has_kasko',
      'is_spare',
      'activated_at',
      'assigned_at',
      'contract_start_at.start',
      'contract_end_at.end',
      'product_id',
      'customer_id',
      'customer_user_id',
      'customers_location_ids',
    ]),
    ...mapState('customersAssets', {
      statuses: (state) => state.statuses,
    }),
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('customersAssets', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
